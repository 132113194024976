import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, startWith, delay } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { Product } from '../classes/product';
import { Subject, Observable } from 'rxjs';
const state = {
  products: JSON.parse(localStorage['products'] || '[]'),
  wishlist: JSON.parse(localStorage['wishlistItems'] || '[]'),
  compare: JSON.parse(localStorage['compareItems'] || '[]'),
  cart: JSON.parse(localStorage['cartItems'] || '[]')
}
const httpOptions = {
  headers: new HttpHeaders({
    Authorization: 'Basic WVdSdGFXND06WVdSdGFXND0='
  })
};
@Injectable({
  providedIn: 'root'
})
export class ProductService {

  public Currency = { name: 'Rupees', currency: 'INR', price: 1 } // Default Currency
  public OpenCart: boolean = false;
  public Products;
  productAddUpdate$ = new Subject<any>();
  userLoggedOutorIn$ = new Subject<any>();
  wishLishtUpdated$ = new Subject<any>();
  productByCategory = new Subject<any>();
  logoutUser = new Subject<any>()
  loggedIn = new Subject<any>();
  refreshData = new Subject<any>()
  //public Baseapi = 'https://prabhujipurefood.com/administration/api/';
  //public Baseapi = 'https://api.prabhujipurefood.com/api/';
  // public Baseapi = 'https://wishinteractiveinc.com/mithas-admin/api/';
  // public Baseapi = 'http://143.110.181.88/ecommerce-admin/api/';
  public Baseapi = 'https://socientdigital.com/superadmin/api/';

  


  constructor(private http: HttpClient,
  private toastrService: ToastrService) { }

  /*
    ---------------------------------------------
    ---------------  Product  -------------------
    ---------------------------------------------
  */

  // Product
  private get products(): Observable<Product[]> {
    this.Products = this.http.get<Product[]>('assets/data/products.json').pipe(map(data => data));
    this.Products.subscribe(next => { localStorage['products'] = JSON.stringify(next) });
    return this.Products = this.Products.pipe(startWith(JSON.parse(localStorage['products'] || '[]')));
  }

  // Get Products
  public get getProducts(): Observable<Product[]> {
    return this.products;
  }

  public registerUser(obj) {
    return this.http.post(this.Baseapi + 'registration', obj, httpOptions)
  }

  public publishNewsletter(obj) {
    return this.http.post(this.Baseapi + 'newsletter-add', obj, httpOptions)
  }

  public postEnquiry(obj) {
    return this.http.post(this.Baseapi + 'enquire', obj, httpOptions)
  }

  public getProductsByDivision(obj) {
    return this.http.post(this.Baseapi + 'products/by-division', obj, httpOptions)
  }

  public changePassword(obj) {
    return this.http.post(this.Baseapi + 'change-password', obj, httpOptions)
  }

  public getProductsByDepartment(obj) {
    return this.http.post(this.Baseapi + 'products/by-deptment', obj, httpOptions)
  }

  public userRegistration(obj) {
    return this.http.post(this.Baseapi + 'registration', obj, httpOptions)
  }

  public forgetPassword(obj) {
    return this.http.post(this.Baseapi + "forget_password", obj)
  }

  public searchItems(obj) {
    return this.http.post(this.Baseapi + "search-product", obj)
  }

  public getProductsBySection(obj) {
    return this.http.post(this.Baseapi + 'products/by-section', obj, httpOptions)
  }

  public getWishListedProducts(obj) {
    return this.http.post(this.Baseapi + 'all-wishlist-products', obj, httpOptions)
  }

  public addGiftWrap(obj) {
    return this.http.post(this.Baseapi + "giftwrap-product", obj, httpOptions);
  }

  public getOrderList(obj) {
    return this.http.post(this.Baseapi + 'order-history', obj)
  }

  public getOrderDetail(obj) {
    return this.http.post(this.Baseapi + 'order-detail', obj)
  }

  public getSlider(obj) {
    return this.http.post(this.Baseapi + 'get-slider', obj, httpOptions)
  }

  public getRelatedProducts(obj) {
    return this.http.post(this.Baseapi + 'products/related', obj, httpOptions)
  }

  public getOrderStatusList(obj) {
    return this.http.post(this.Baseapi + 'order-statuslist', obj, httpOptions)
  }


  public getDepartmentList(obj) {
    return this.http.post(this.Baseapi + 'department-list', obj, httpOptions)
  }

  public getProductsForHer(obj) {
    return this.http.post(this.Baseapi + 'products/for-her', obj, httpOptions)
  }

  public login(obj) {
    return this.http.post(this.Baseapi + 'login', obj, httpOptions)
  }

  public filterbyProducts(obj) {
    return this.http.post(this.Baseapi + 'products/filter', obj)
  }

  public updateBankingDetails(obj) {
    return this.http.post(this.Baseapi + 'add_bank', obj)
  }

  public getAddressList(obj) {
    return this.http.post(this.Baseapi + 'address_list', obj)
  }

  public removeAddress(obj) {
    return this.http.post(this.Baseapi + 'address_delete', obj)
  }
  public addtowishlist(obj) {
    return this.http.post(this.Baseapi + 'product-updatetowishlist', obj)
  }
  public getwishlist(obj) {
    return this.http.post(this.Baseapi + 'user-wishlistproduct', obj)
  }
  public getStates() {
    return this.http.get(this.Baseapi + 'state')
  }

  public contactUs(obj) {
    return this.http.post(this.Baseapi + 'contact-us', obj)
  }

  public getBreadCrumbs(obj) {
    return this.http.post(this.Baseapi + 'getbreadcrumb', obj)
  }

  public removeGiftWrap(obj) {
    return this.http.post(this.Baseapi + 'remove-giftwrap', obj)
  }

  public getAllSizes() {
    return this.http.get(this.Baseapi + 'getall-size')
  }

  public getSliderImages() {
    return this.http.get(this.Baseapi + 'slider')
  }



  // Get Products By Slug
  public getProductBySlug(slug: string): Observable<Product> {
    return this.products.pipe(map(items => {
      return items.find((item: any) => {
        return item.title.replace(' ', '-') === slug;
      });
    }));
  }

  public getBankDetails(obj) {
    return this.http.post(this.Baseapi + 'bankdetail', obj, httpOptions)
  }

  public getBestSellers(obj) {
    return this.http.post(this.Baseapi + 'products/best-seller', obj, httpOptions)
  }

  public getMarquee(){
    return this.http.get('https://admin.prabhujipurefood.com/assets/jsonfiles/deliverycharges.json')
  }

  public getProductDetails(obj) {
    return this.http.post(this.Baseapi + 'product-detail', obj, httpOptions)
  }

  public checkout(obj) {
    return this.http.post(this.Baseapi + 'checkout', obj, httpOptions)
  }

  public paycheckout(obj) {
    return this.http.post('https://admin.prabhujipurefood.com/payment-easebuzz/easebuzz.php', obj, httpOptions)
  }

  public checkPincode(obj) {
    return this.http.post(this.Baseapi + 'postalcode-check', obj, httpOptions)
  }


  public addProToWishlist(obj) {
    return this.http.post(this.Baseapi + 'product-updatetowishlist', obj, httpOptions)
  }

  public listWishlist(obj) {
    return this.http.post(this.Baseapi + 'user-wishlistproduct', obj, httpOptions)
  }

  public cancelOrder(obj) {
    return this.http.post(this.Baseapi + 'order-cancel', obj, httpOptions)
  }

  public returnSingleProduct(obj) {
    return this.http.post(this.Baseapi + 'product-return', obj, httpOptions)
  }

  public checkIfBuyed(obj) {
    return this.http.post(this.Baseapi + 'condition', obj, httpOptions)
  }

  public returnOrder(obj) {
    return this.http.post(this.Baseapi + 'order-return', obj, httpOptions)
  }
  /*
    ---------------------------------------------
    ---------------  Wish List  -----------------
    ---------------------------------------------
  */

  // Get Wishlist Items
  public get wishlistItems(): Observable<Product[]> {
    const itemsStream = new Observable(observer => {
      observer.next(state.wishlist);
      observer.complete();
    });
    return <Observable<Product[]>>itemsStream;
  }

  // Add to Wishlist
  public addToWishlist(product): any {
    const wishlistItem = state.wishlist.find(item => item.id === product.id)
    if (!wishlistItem) {
      state.wishlist.push({
        ...product
      })
    }
    this.toastrService.success('Product has been added in wishlist.');
    localStorage.setItem("wishlistItems", JSON.stringify(state.wishlist));
    return true
  }

  // Remove Wishlist items
  public removeWishlistItem(product: Product): any {
    const index = state.wishlist.indexOf(product);
    state.wishlist.splice(index, 1);
    localStorage.setItem("wishlistItems", JSON.stringify(state.wishlist));
    return true
  }

  /*
    ---------------------------------------------
    -------------  Compare Product  -------------
    ---------------------------------------------
  */

  // Get Compare Items
  public get compareItems(): Observable<Product[]> {
    const itemsStream = new Observable(observer => {
      observer.next(state.compare);
      observer.complete();
    });
    return <Observable<Product[]>>itemsStream;
  }

  // Add to Compare
  public addToCompare(product): any {
    const compareItem = state.compare.find(item => item.id === product.id)
    if (!compareItem) {
      state.compare.push({
        ...product
      })
    }
    this.toastrService.success('Product has been added in compare.');
    localStorage.setItem("compareItems", JSON.stringify(state.compare));
    return true
  }

  // Remove Compare items
  public removeCompareItem(product: Product): any {
    const index = state.compare.indexOf(product);
    state.compare.splice(index, 1);
    localStorage.setItem("compareItems", JSON.stringify(state.compare));
    return true
  }

  /*
    ---------------------------------------------
    -----------------  Cart  --------------------
    ---------------------------------------------
  */

  // Get Cart Items
  public get cartItems(): Observable<any[]> {
    const itemsStream = new Observable(observer => {
      if (localStorage.isLoggedIn) {
        let customerId = localStorage.customerId;
        this.http.post(this.Baseapi + 'cartlist', { "user_id": customerId }, httpOptions).subscribe(res => {
          //console.log(res)
          observer.next(res);
          observer.complete();
        }, error => {
          observer.next([]);
          observer.complete();
        })
      }


    });
    return <Observable<any[]>>itemsStream;
  }

  public listCartItems() {
    if (localStorage.isLoggedIn) {
      let customerId = localStorage.customerId;
      return this.http.post(this.Baseapi + 'cartlist', { "user_id": customerId }, httpOptions)
    }
  }


  // Add to Cart
  public addToCart(product): any {
    console.log(product);
    if (localStorage.isLoggedIn) {
      let obj = {
        "user_id": localStorage.customerId,
        "qty": product.quantity,
        "itemvariants_id": product.itemvariant[0].itemid
      }
      console.log(obj);
      this.http.post(this.Baseapi + 'add-to-cart', obj, httpOptions).subscribe(res => {
        console.log(res);
        this.productAddUpdate$.next('added')
      }, error => {

      })
    } else {
      this.toastrService.success("Please login to add to cart")
    }
  }


  public addFromCartPage(product): any {
    console.log("add to cart data ....", product);
    if (localStorage.isLoggedIn) {
      let obj: any = {}
      obj.user_id = localStorage.customerId
      obj.product_id = product.product_id
      obj.qty = JSON.stringify(product.product_qty)
      obj.price = product.product_price
      obj.weight = product.weight
      obj.weight_type = product.weight_type
      obj.main_price = product.main_price
      console.log(obj);
      this.http.post(this.Baseapi + 'add-to-cart', obj, httpOptions).subscribe(res => {
        console.log(res);
        this.productAddUpdate$.next('added')
      }, error => {

      })
    }

  }

  public returnFullOrder(obj) {
    return this.http.post(this.Baseapi + 'order-return', obj)
  }

  public applyDiscount(obj) {
    return this.http.post(this.Baseapi + 'discountapply', obj)
  }




  //Haldiram api
  calulatetax(obj) {
    return this.http.post(this.Baseapi + 'get-product-tax', obj)
  }
  public paymentMode() {
    return this.http.get(this.Baseapi + 'payment-mode')
  }
  public guestcheckout(obj) {
    return this.http.post(this.Baseapi + 'guest-checkout', obj)
  }
  public addAddress(obj) {
    return this.http.post(this.Baseapi + 'add_address', obj)
  }
  public submitReview(obj) {
    return this.http.post(this.Baseapi + 'review', obj)
  }
  public getFormatType() {
    return this.http.get(this.Baseapi + 'product-formatetype')
  }
  public updateProfile(obj) {
    return this.http.post(this.Baseapi + 'profile-update', obj)
  }
  public getUserProfileDetails(obj) {
    return this.http.post(this.Baseapi + 'profile-view', obj)
  }
  public getCategory() {
    return this.http.get(this.Baseapi + 'category-list')
  }
  public getSubCategory(obj) {
    return this.http.get(this.Baseapi + 'subcategory-list/' + obj)
  }
  public productAll(obj) {
    return this.http.post(this.Baseapi + 'product-all', obj)
  }
  public productBySlug(obj){
    return this.http.post(this.Baseapi+'product-all-with-slug',obj);
  }
  public productDetails(obj) {
    return this.http.post(this.Baseapi + 'product-detail', obj)
  }
  public addtoCart(obj) {
    return this.http.post(this.Baseapi + 'add-to-cart', obj)
  }
  public relatedProductApi(obj) {
    return this.http.post(this.Baseapi + 'product-related', obj)
  }
  public cartList(obj) {
    return this.http.post(this.Baseapi + 'cartlist', obj)
  }
  public removeItems(obj) {
    return this.http.post(this.Baseapi + 'cart/remove-item', obj, httpOptions)
  }
  public subscription(obj) {
    return this.http.post(this.Baseapi + 'subscribe', obj)
  }
  public getDiscount(obj) {
    return this.http.post(this.Baseapi + 'check-coupon', obj)
  }
  public getVerifiedOrder(obj) {
    return this.http.post(this.Baseapi + 'verifyorder', obj)
  }
  public getstate() {
    return this.http.get(this.Baseapi + 'state')
  }
  public getPincode(obj) {
    return this.http.post(this.Baseapi + 'postalcode-check', obj)
  }
  public checkoutproduct(obj) {
    return this.http.post(this.Baseapi + "checkout", obj)
  }
  public orderList(obj) {
    return this.http.post(this.Baseapi + "order-history", obj)
  }
  public orderDetails(obj) {
    return this.http.post(this.Baseapi + "order-detail", obj)
  }

  public lastorderDetails(obj) {
    return this.http.post(this.Baseapi + "lastorderdetail", obj)
  }
  public seoSettingData(obj) {
    return this.http.post(this.Baseapi + "seo_setting", obj)
  }
  public getlagacy() {
    return this.http.get(this.Baseapi + 'homepage-data/lagacy')
  }

  public getlagacycenter() {
    return this.http.get(this.Baseapi + 'homepage-data/legacy-center')
  }

  public getlagacyright() {
    return this.http.get(this.Baseapi + 'homepage-data/legacy-right')
  }

  // public gettopsellers(){
  //   return this.http.get(this.Baseapi+'homepage-data/top_sellers')
  // }

  public gettrandingproduct() {
    return this.http.get(this.Baseapi + 'tranding-product')
  }

  public getrecipes() {
    return this.http.get(this.Baseapi + 'recipes')
  }

  public getnewarrivalProducts() {
    return this.http.get(this.Baseapi + 'newarrival-product')
  }

  public gettopsellers() {
    return this.http.get(this.Baseapi + 'topselling-product')
  }
  public getDryFruits() {
    return this.http.get(this.Baseapi + 'homepage-data/dry_fruits')
  }
  public getDeals() {
    return this.http.get(this.Baseapi + 'homepage-data/deals')
  }
  public gettop_sellersfour() {
    return this.http.get(this.Baseapi + 'homepage-data/top_sellers')
  }
  public getGifting() {
    return this.http.get(this.Baseapi + 'homepage-data/gifting')
  }
  public getSnacks() {
    return this.http.get(this.Baseapi + 'homepage-data/snacks')
  }
  public getMinuteskhana() {
    return this.http.get(this.Baseapi + 'homepage-data/minutes_khana')
  }

  public getCategoryHome() {
    return this.http.get(this.Baseapi + 'categorymasterhomeslider')
  }

  public getAllProducts(obj) {
    return this.http.post(this.Baseapi + 'product-all', obj)
  }

  public BulkOrder(obj) {
    return this.http.post(this.Baseapi + 'bulk_order', obj)
  }

  public getShippingBanner() {
    return this.http.get(this.Baseapi + 'shipping-banner')
  }

  public getdeliverytext() {
    return this.http.get(this.Baseapi + 'deliverytext')
  }

  

  //end of haldiram api
  public addToItemCart(quantity, selectedVariant): any {
    //if(localStorage.isLoggedIn){
    let obj = {
      "user_id": localStorage.customerId,
      "qty": quantity,
      "itemvariants_id": selectedVariant
    }
    console.log(obj);
    this.http.post(this.Baseapi + 'add-to-cart', obj, httpOptions).subscribe(res => {
      console.log(res);
      this.productAddUpdate$.next('added')
    }, error => {

    })
    // }else{
    //   this.toastrService.success("Please login to add to cart")
    // }
  }
  // Update Cart Quantity
  public updateCartQuantity(product: Product, quantity: number): Product | boolean {
    return state.cart.find((items, index) => {
      if (items.id === product.id) {
        const qty = state.cart[index].quantity + quantity
        const stock = this.calculateStockCounts(state.cart[index], quantity)
        if (qty !== 0 && stock) {
          state.cart[index].quantity = qty
        }
        localStorage.setItem("cartItems", JSON.stringify(state.cart));
        return true
      }
    })
  }

  // Calculate Stock Counts
  public calculateStockCounts(product, quantity) {
    const qty = product.quantity + quantity
    const stock = product.stock
    if (stock < qty || stock == 0) {
      this.toastrService.error('You can not add more items than available. In stock ' + stock + ' items.');
      return false
    }
    return true
  }

  // Remove Cart items
  public removeCartItem(product: Product): any {
    const index = state.cart.indexOf(product);
    state.cart.splice(index, 1);
    localStorage.setItem("cartItems", JSON.stringify(state.cart));
    return true
  }

  // Total amount 
  // public cartTotalAmount(): Observable<number> {
  //   // return this.cartItems.pipe(map((product: Product[]) => {
  //   //   return product.reduce((prev, curr: Product) => {
  //   //     let price = curr.price;
  //   //     if(curr.discount) {
  //   //       price = curr.price - (curr.price * curr.discount / 100)
  //   //     }
  //   //     return (prev + price * curr.quantity) * this.Currency.price;
  //   //   }, 0);
  //   // }));
  // }

  /*
    ---------------------------------------------
    ------------  Filter Product  ---------------
    ---------------------------------------------
  */

  // Get Product Filter
  public filterProducts(filter: any): Observable<Product[]> {
    return this.products.pipe(map(product =>
      product.filter((item: Product) => {
        if (!filter.length) return true
        const Tags = filter.some((prev) => { // Match Tags
          if (item.tags) {
            if (item.tags.includes(prev)) {
              return prev
            }
          }
        })
        return Tags
      })
    ));
  }

  // Sorting Filter
  public sortProducts(products: Product[], payload: string): any {

    if (payload === 'ascending') {
      return products.sort((a, b) => {
        if (a.id < b.id) {
          return -1;
        } else if (a.id > b.id) {
          return 1;
        }
        return 0;
      })
    } else if (payload === 'a-z') {
      return products.sort((a, b) => {
        if (a.title < b.title) {
          return -1;
        } else if (a.title > b.title) {
          return 1;
        }
        return 0;
      })
    } else if (payload === 'z-a') {
      return products.sort((a, b) => {
        if (a.title > b.title) {
          return -1;
        } else if (a.title < b.title) {
          return 1;
        }
        return 0;
      })
    } else if (payload === 'low') {
      return products.sort((a, b) => {
        if (a.price < b.price) {
          return -1;
        } else if (a.price > b.price) {
          return 1;
        }
        return 0;
      })
    } else if (payload === 'high') {
      return products.sort((a, b) => {
        if (a.price > b.price) {
          return -1;
        } else if (a.price < b.price) {
          return 1;
        }
        return 0;
      })
    }
  }

  /*
    ---------------------------------------------
    ------------- Product Pagination  -----------
    ---------------------------------------------
  */
  public getPager(totalItems: number, currentPage: number = 1, pageSize: number = 16) {
    // calculate total pages
    let totalPages = Math.ceil(totalItems / pageSize);

    // Paginate Range
    let paginateRange = 3;

    // ensure current page isn't out of range
    if (currentPage < 1) {
      currentPage = 1;
    } else if (currentPage > totalPages) {
      currentPage = totalPages;
    }

    let startPage: number, endPage: number;
    if (totalPages <= 5) {
      startPage = 1;
      endPage = totalPages;
    } else if (currentPage < paginateRange - 1) {
      startPage = 1;
      endPage = startPage + paginateRange - 1;
    } else {
      startPage = currentPage - 1;
      endPage = currentPage + 1;
    }

    // calculate start and end item indexes
    let startIndex = (currentPage - 1) * pageSize;
    let endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

    // create an array of pages to ng-repeat in the pager control
    let pages = Array.from(Array((endPage + 1) - startPage).keys()).map(i => startPage + i);

    // return object with all pager properties required by the view
    return {
      totalItems: totalItems,
      currentPage: currentPage,
      pageSize: pageSize,
      totalPages: totalPages,
      startPage: startPage,
      endPage: endPage,
      startIndex: startIndex,
      endIndex: endIndex,
      pages: pages
    };
  }

}
