<div class="navbar" style="padding: 0;">
   <a href="javascript:void(0)" (click)="leftMenuToggle()">
      <div class="toggleBar"><span class="material-icons">menu</span></div>
   </a>
   
   <div id="mySidenav" class="sidenav" [class.openSide]="navServices?.leftMenuToggle">
      <a href="javascript:void(0)" class="sidebar-overlay"></a>
      <nav>
         <div (click)="leftMenuToggle()">
            <div class="sidebar-back text-left"><i class="fa fa-angle-left pr-2" aria-hidden="true"></i> Back
            </div>
         </div>
         <ul id="sub-menu" class="sidebar-menu">
            <li *ngFor="let cat of category" (click)="getDataByCat(cat); leftMenuToggle()">
               {{cat.category_name}}
            </li>
         </ul>
      </nav>
   </div>
</div>