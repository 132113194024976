import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Product } from '../../../../shared/classes/product';
import { ProductService } from '../../../../shared/services/product.service';

@Component({
  selector: 'app-related-product',
  templateUrl: './related-product.component.html',
  styleUrls: ['./related-product.component.scss']
})
export class RelatedProductComponent implements OnInit {
  
  @Input() type: string
  productData: any = [];
  slug: any;

  // public products: Product[] = [];
  

  constructor(public productService: ProductService,public route:ActivatedRoute) { 
    // this.productService.getProducts.subscribe(response => 
    //   this.products = response.filter(item => item.type == this.type)
    // );
    
  }

  ngOnInit(): void {
    this.slug = this.route.snapshot.params.slug
    console.log(this.route.snapshot.params.slug)
  
    let obj = {
      "perpage":"12",
      "page":"0",
      "slug":this.slug
      
    }
    this.productService.relatedProductApi(obj).subscribe(res=>{
      console.log(res)
      this.productData = res['data']
    })
  }

}
