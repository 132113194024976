import { Component, OnInit } from '@angular/core';
import { NavService, Menu } from '../../services/nav.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ProductService } from '../../services/product.service';

@Component({
  selector: 'app-left-menu',
  templateUrl: './left-menu.component.html',
  styleUrls: ['./left-menu.component.scss']
})

export class LeftMenuComponent implements OnInit {

  public menuItems: Menu[];
  categories: any = [];
  public category: any = [];
  public subCategory: any = [];

  constructor(public navServices: NavService, public productService: ProductService, private router: Router, public route: ActivatedRoute,) {
    // this.navServices.getMenus().subscribe(res=>{
    //   console.log(res['data'])
    //   let data = res['data'];
    //   data.forEach(element => {
    //     console.log(element.division.division_name)
    //   });

    // })

    // let menuArr=[];
    // menuArr.push({title: 'Home',path:"/home", type: 'link', active: false});
    // menuArr.push({
    //   title: 'KURTA SETS', type: 'link', active: false,path:'/shop/collection/department/4/kurta-sets'
    // },
    // {
    //   title:'KURTIES',type:'link', active: false,path:'/shop/collection/department/4/kurtas-kurties'
    // }
    // )

    //this.menuItems = menuArr

    //this.navServices.leftMenuItems.subscribe(menuItems => this.menuItems = menuItems );
    this.router.events.subscribe((event) => {
      this.navServices.mainMenuToggle = false;
    });
  }
  getDataByCat(cat){
    console.log(cat);
    this.router.navigateByUrl(`/product/category/${cat.slug}`)
    
    }

    ngOnInit(): void {
      this.route.queryParams.subscribe(params => {
        this.productService.getCategory().subscribe(async (res: any) => {
          // console.log("category",res)
          this.category = res.data
          for (var [index1, value] of this.category.entries()) {
            // console.log(index1,value)
            await this.productService.getSubCategory(value.id).subscribe(res => {
              if (res) {
                if (res['data'].length > 0) {
  
                  let id = parseInt(res['data'][0].cat_id);
                  let index = this.category.findIndex(x => x.id === id);
                  // console.log(id);
                  if (index >= 0) {
                    this.category[index]['subCategory'] = res['data'];
                    // console.log(this.category)
                  }
  
                }
  
                //this.lagacy = res['data'];
              }
            })
            //   console.log("cateogyr"+this.category[index1].id); 
  
          }
          // console.log(this.category)
          localStorage.setItem("subCategory", JSON.stringify(this.subCategory))
  
          localStorage.setItem("categories", JSON.stringify(this.category))
        })
      })
    }

  leftMenuToggle(): void {
    this.navServices.leftMenuToggle = !this.navServices.leftMenuToggle;
  }

  // Click Toggle menu (Mobile)
  toggletNavActive(item) {
    item.active = !item.active;
  }

}
