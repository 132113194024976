import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ViewportScroller } from '@angular/common';
import { ProductService } from "../../../shared/services/product.service";
import { HomeSlider } from '../../../shared/data/slider';
import { response } from 'express';
import { Meta, Title } from '@angular/platform-browser';
import { Options, LabelType } from 'ng5-slider';

@Component({
  selector: 'app-collection-left-sidebar',
  templateUrl: './collection-left-sidebar.component.html',
  styleUrls: ['./collection-left-sidebar.component.scss']
})
export class CollectionLeftSidebarComponent implements OnInit {


  public grid: string = 'col-xl-4 col-md-4 col-sm-4 col-6';
  public layoutView: string = 'grid-view';
  public products: any[] = [];
  public brands: any[] = [];
  public productBrands: any[] = [];
  public colors: any[] = [];
  public formats: any[] = [];
  public types: any[] = []
  public productColors: any[] = [];
  public size: any[] = [];
  public minPrice: number = 0;
  public maxPrice: any = 0;
  public tags: any[] = [];
  public category: string;
  public pageNo: number = 1;
  public paginate: any = {}; // Pagination use only
  public sortBy: string; // Sorting Order
  public mobileSidebar: boolean = false;
  public loader: boolean = true;
  public division: any;
  public bannerImage: any;
  productLoaded: boolean = false
  public HomeSliderConfig: any = HomeSlider;
  public selectedSort = '';

  public sliders = []
  productsSize: any[] = [];
  priceMax: number;
  priceMin: number;
  productData: any = [];
  id: any;
  bottomDescription: any;
  categories: any = [];
  notFound: boolean;
  message: boolean;
  catname: any;
  format: any;
  params: any = [];
  number: void;
  type: any;

  public collapse: boolean = false;
  filterData: any;
  filterCategories: any = [];
  filterSizes: any = [];
  selectedCat: any = [];
  selectedWeight: any = [];

  constructor(private route: ActivatedRoute, private router: Router,
    private title: Title, private meta: Meta,
    private viewScroller: ViewportScroller, public productService: ProductService) {
    // Get Query params..
    //alert("here")
    this.route.queryParams.subscribe(params => {
      //  this.ngOnInit();

      this.brands = params.brand ? params.brand.split(",") : [];
      this.types = params.type ? params.type.split(",") : [];
      this.colors = params.color ? params.color.split(",") : [];
      this.formats = params.format ? params.format.split(",") : [];
      this.size = params.size ? params.size.split(",") : [];
      this.minPrice = params.minPrice ? params.minPrice : this.minPrice;
      this.maxPrice = params.maxPrice ? params.maxPrice : this.maxPrice;
      this.tags = [...this.brands, ...this.colors, ...this.size]; // All Tags Array

      this.category = params.category ? params.category : null;
      this.sortBy = params.sortBy ? params.sortBy : 'ascending';
      this.pageNo = params.page ? params.page : this.pageNo;

      // Get Filtered Products..
      // this.productService.filterProducts(this.tags).subscribe(response => {         
      //   // Sorting Filter
      //   this.products = this.productService.sortProducts(response, this.sortBy);
      //   // Category Filter
      //   if(params.category)
      //     this.products = this.products.filter(item => item.type == this.category);
      //   // Price Filter
      //   this.products = this.products.filter(item => item.price >= this.minPrice && item.price <= this.maxPrice) 
      //   // Paginate Products
      //   this.paginate = this.productService.getPager(this.products.length, +this.pageNo);     // get paginate object from service
      //   this.products = this.products.slice(this.paginate.startIndex, this.paginate.endIndex + 1); // get current page of items
      // })
    })
  }

  minValue: number;
  maxValue: number;
  options: any

  ngOnInit(): void {
    //    alert("here")
    this.type = this.route.snapshot.params["type"];
    console.log(this.type)
    this.productService.getFormatType().subscribe((res: any) => {
      this.format = res.data
      this.maxPrice = res.data[1].price_range
      console.log(this.maxPrice)
    })



    localStorage.setItem("ordersuccess", "notdone")
    this.route.params.subscribe(params => {
      this.getMetaTags();
      this.getProductData();
      this.categories = JSON.parse(localStorage.getItem("categories"))
      this.categories.forEach(element => {
        if (this.route.snapshot.params['slug'] == element.slug) {
           this.catname = element.category_name
          // this.title.setTitle(element.title);
          // this.meta.addTag({ name: 'description', content: element.description })
        }
      });


      if (this.id == 16) {
        this.catname = 'Snacks'
      }
      if (this.id == '15') {
        this.catname = 'Dry Fruits'
      }
      if (this.id == '17') {
        this.catname = 'Deals'
      }
      if (this.id == '18') {
        this.catname = 'Top sellers'
      }
      if (this.id == '22') {
        this.catname = 'Miniute Khana'
      }
      if (this.id == '19') {
        this.catname = 'Quick Meals'
      }
    })

    // this.route.params.subscribe(params => {
    //   console.log(params)
    //   this.division = params.division;
    //   console.log(this.division);
    //   if(this.division == 'men'){
    //     this.bannerImage = '/assets/images/men-fashion.jpg'
    //   }
    //   if(this.division == 'women'){
    //     this.bannerImage = '/assets/images/Bannerhomepage1.jpg'
    //   }
    //   let obj;
    //   let sliderObj;
    //   if(this.division == 'men'){
    //    obj = {
    //      "id":1
    //    }
    //    sliderObj={
    //     "category_id":1
    //    }
    //   }
    //   if(this.division == 'women'){
    //     obj = {
    //       "id":2
    //     }
    //     sliderObj={
    //       "category_id":1
    //      }
    //   }
    //   this.productService.getProductsByDivision(obj).subscribe(res=>{
    //     console.log(res);
    //     this.products = res['data'];
    //     let self=this;
    //     let priceArr = []
    //     this.products.filter((product) => {
    //       priceArr.push(product.priceInclusive)
    //       if (product.item_brand) {
    //         const index = self.productBrands.indexOf(product.item_brand)
    //         if (index === -1) self.productBrands.push(product.item_brand)
    //       }
    //       if(product.item_color){
    //         const index = self.productColors.indexOf(product.item_color)
    //         if (index === -1) self.productColors.push(product.item_color)
    //       }
    //     });
    //     let max = Math.max(...priceArr);
    //     let min = Math.min(...priceArr);
    //     this.priceMax= max;
    //     this.priceMin = min;
    //     this.productsSize = ['S','M','L','XL','2XL', '3XL'];
    //     this.viewScroller.setOffset([120, 120]);
    //     this.viewScroller.scrollToAnchor('products'); 
    //   },error=>{
    //     this.productLoaded = true;
    //     this.products = [];
    //     this.viewScroller.setOffset([120, 120]);
    //     this.viewScroller.scrollToAnchor('products');
    //   })
    //   this.productService.getSlider(sliderObj).subscribe(res=>{
    //       console.log(res)
    //       let sliders = res['data']['image_arr'];
    //       let self= this;
    //       sliders.forEach(element => {
    //         console.log(element)
    //         let obj;
    //         obj = {
    //           title: 'welcome to fashion',
    //           subTitle: 'pakiza collections',
    //           image: element

    //         };
    //         self.sliders.push(obj)
    //       });
    //       console.log(self.sliders)
    //   })
    // })
  }

  appliedFilter($event) {
    console.log($event);
    this.minPrice = $event.value;
    this.maxPrice = $event.highValue;
    this.getProductFilterData();
  }


  getMetaTags() {
    let obj: any = {};
    obj.url = window.location.href;
    this.productService.seoSettingData(obj).subscribe((res: any) => {
      this.title.setTitle(res.data.title);
      this.meta.addTag({ name: 'description', content: res.data.description });
      this.meta.addTag({ name: 'keyword', content: res.data.keyword })
    });
  }

  getProductData() {
    this.route.queryParams.subscribe(params => {
      //  console.log("parameter", params, params['format'])
      //console.log(this.route.snapshot.params["slug-:id"], "dfgjdf")

      this.params = this.route.snapshot.params["slug"]
      this.type = this.route.snapshot.params["type"];
      //console.log(this.params, this.id, this.type)

      // let numbers = this.params.filter(numbersOnly);
      // function numbersOnly(value) {
      //     if (typeof (parseInt(value)) === 'number') {
      //       console.log(value,"jfg")
      //         return value;
      //     }
      // }
      // console.log(numbers , "number")

      // var filtered = this.params.filter(function (item) {
      //   return (typeof (item) === "number")
      // });

      console.log(this.route.snapshot.params.id)
      let obj: any = {}
      if (this.type === 'category') {
        obj.category = this.params
      } else {
        obj.subcatid = this.params
      }

      obj.perpage = "50";
      obj.page = '0';
      obj.min_price = this.minPrice;
      obj.max_price = this.maxPrice;
      obj.type = params['type']
      obj.format = params['format']
      if (this.sortBy == "DESC") {
        obj.sortValue = 'DESC'
        obj.sortBy = "main_price"
      }
      if (this.sortBy == "ASC") {
        obj.sortValue = 'ASC'
        obj.sortBy = "main_price"
      }
      if (this.sortBy == 'latest') {
        obj.sortValue = 'DESC'
        obj.sortBy = "latest"
      }
      this.productService.productBySlug(obj).subscribe((res: any) => {
        console.log("Data", res.data[0].category)
        this.message = false
        this.productData = res.data;
        this.bottomDescription = res.category_description
        this.filterData = res.filter;
        this.minValue = this.filterData.price_range.min_price;
        this.maxValue = this.filterData.price_range.max_price;
        this.filterCategories = this.filterData.subcategory;
        this.filterSizes = this.filterData.pack_size;
        this.options = {
          floor: this.minValue,
          ceil: this.maxValue,
          translate: (value: number, label: LabelType): string => {
            switch (label) {
              case LabelType.Low:
                return '<b>Min price:</b> Rs' + value;
              case LabelType.High:
                return '<b>Max price:</b> Rs' + value;
              default:
                return 'Rs' + value;
            }
          }
        };
        console.log(this.productData.product_image)

      }, err => {
        if (err) {
          this.message = true;
          this.productLoaded = true;
          this.productData = []
        }

      })
    })

  }

  addCatFilter($event) {
    let arr = this.selectedCat;
    if ($event.target.checked) {
      if (arr.indexOf($event.target.value) < 0) {
        arr.push($event.target.value)
      }
    } else {
      let index = arr.indexOf($event.target.value);
      if (index >= 0) {
        arr.splice(index, 1)
      }

    }
    this.selectedCat = arr;
    this.getProductFilterData();
  }

  addWeightFilter($event) {
    let arr = this.selectedWeight;
    if ($event.target.checked) {
      if (arr.indexOf($event.target.value) < 0) {
        arr.push($event.target.value)
      }
    } else {
      let index = arr.indexOf($event.target.value);
      if (index >= 0) {
        arr.splice(index, 1)
      }

    }
    this.selectedWeight = arr;
    this.getProductFilterData();
  }

  getProductFilterData() {
    this.route.queryParams.subscribe(params => {
      console.log("parameter", params, params['format'])
     // this.id = this.route.snapshot.params.id
      console.log(this.route.snapshot.params["slug"], "dfgjdf")

      //this.route.snapshot.params["slug-:id"]
      this.params = this.route.snapshot.params["slug"]
      this.type = this.route.snapshot.params["type"];
     

      // let numbers = this.params.filter(numbersOnly);
      // function numbersOnly(value) {
      //     if (typeof (parseInt(value)) === 'number') {
      //       console.log(value,"jfg")
      //         return value;
      //     }
      // }
      // console.log(numbers , "number")

      // var filtered = this.params.filter(function (item) {
      //   return (typeof (item) === "number")
      // });

     // console.log(this.route.snapshot.params.id)
      let obj: any = {}
      if (this.type === 'category') {
        obj.category = this.params
      } else {
        obj.subcatid = this.params
      }

      obj.perpage = "50";
      obj.page = '0';
      obj.min_price = this.minPrice;
      obj.max_price = this.maxPrice;
      obj.type = params['type']
      obj.format = params['format']
      if (this.sortBy == "DESC") {
        obj.sortValue = 'DESC'
        obj.sortBy = "main_price"
      }
      if (this.sortBy == "ASC") {
        obj.sortValue = 'ASC'
        obj.sortBy = "main_price"
      }
      if (this.sortBy == 'latest') {
        obj.sortValue = 'DESC'
        obj.sortBy = "latest"
      }
      if (this.selectedCat.length > 0) {
        obj.subcatid = this.selectedCat.join(',');
      }
      if (this.selectedWeight.length > 0) {
        obj.packsize = this.selectedWeight.join(',');
      }
      this.productService.productBySlug(obj).subscribe((res: any) => {
        this.message = false
        this.productData = res.data;
        // console.log("Data", res.data)
        this.filterData = res.filter;
        // this.minValue = this.filterData.price_range.min_price;
        // this.maxValue = this.filterData.price_range.max_price;
        //this.filterCategories = this.filterData.subcategory;
        //this.filterSizes = this.filterData.pack_size;

        // console.log(this.productData.product_image)

      }, err => {
        if (err) {
          this.message = true;
          this.productLoaded = true;
          this.productData = []
        }

      })
    })

  }

  // Append filter value to Url
  updateFilter(tags: any) {
    tags.page = null; // Reset Pagination
    // console.log("tag", tags)
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: tags,
      queryParamsHandling: 'merge', // preserve the existing query params in the route
      skipLocationChange: false  // do trigger navigation
    }).finally(() => {
      this.route.queryParams.subscribe(params => {
        // console.log(params);
        let obj = {
        }
        if (params['color']) {
          // console.log(params['color']);
          obj['item_color'] = params['color'].split(',')
        }

        // if(params['category']){
        //   if(params['category'] !=''){
        //     obj['dept_id']=params['category']
        //   }

        // }

        if (params['brand']) {
          // console.log(params['brand']);
          obj['item_brand'] = params['brand'].split(',')
        }
        if (params['sort']) {

          obj['sort'] = params['sort']
        }
        // if(params['size']){
        //   console.log(params['size'])
        //   obj['item_size'] = params['size'].split(',')
        // }
        if (params['minPrice']) {
          obj['item_pricerange'] = [params['minPrice'], params['maxPrice']]
        }
        this.getProductData()
      });
      this.viewScroller.setOffset([120, 120]);
      this.viewScroller.scrollToAnchor('products');
    });
  }

  // SortBy Filter
  sortByFilter(value) {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { sortBy: value ? value : null },
      queryParamsHandling: 'merge', // preserve the existing query params in the route
      skipLocationChange: false  // do trigger navigation
    }).finally(() => {

      this.viewScroller.setOffset([120, 120]);
      this.viewScroller.scrollToAnchor('products'); // Anchore Link
    });
  }

  // Remove Tag
  removeTag(tag) {

    this.brands = this.brands.filter(val => val !== tag);
    this.colors = this.colors.filter(val => val !== tag);
    this.size = this.size.filter(val => val !== tag);

    let params = {
      brand: this.brands.length ? this.brands.join(",") : null,
      color: this.colors.length ? this.colors.join(",") : null,
      size: this.size.length ? this.size.join(",") : null
    }

    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: params,
      queryParamsHandling: 'merge', // preserve the existing query params in the route
      skipLocationChange: false  // do trigger navigation
    }).finally(() => {
      this.route.queryParams.subscribe(params => {
        // console.log(params);
        let obj = {
          "division_id": (this.division == 'women') ? '2' : '1',
        }
        if (params['color']) {
          console.log(params['color']);
          obj['item_color'] = params['color'].split(',')
        }

        if (params['brand']) {
          console.log(params['brand']);
          obj['item_brand'] = params['brand'].split(',')
        }
        if (params['size']) {
          console.log(params['size'])
          obj['item_size'] = params['size'].split(',')
        }
        if (params['minPrice']) {
          obj['item_pricerange'] = [params['minPrice'], params['maxPrice']]
        }
        console.log(obj);

        this.productService.filterbyProducts(obj).subscribe(res => {
          console.log(res);
          this.products = res['data']
        }, error => {
          this.products = [];
          this.productLoaded = true;
        })
      });
      this.viewScroller.setOffset([120, 120]);
      this.viewScroller.scrollToAnchor('products');
    });
  }

  // Clear Tags
  removeAllTags() {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {},
      skipLocationChange: false  // do trigger navigation
    }).finally(() => {
      this.route.params.subscribe(params => {
        console.log(params)
        this.division = params.division;
        console.log(this.division);
        if (this.division == 'men') {
          this.bannerImage = '/assets/images/men-fashion.jpg'
        }
        if (this.division == 'women') {
          this.bannerImage = '/assets/images/Bannerhomepage1.jpg'
        }
        let obj;
        let sliderObj;
        if (this.division == 'men') {
          obj = {
            "id": 1
          }
          sliderObj = {
            "category_id": 1
          }
        }
        if (this.division == 'women') {
          obj = {
            "id": 2
          }
          sliderObj = {
            "category_id": 1
          }
        }
        this.productService.getProductsByDivision(obj).subscribe(res => {
          console.log(res);
          this.products = res['data'];
          this.viewScroller.setOffset([120, 120]);
          this.viewScroller.scrollToAnchor('products');
        }, error => {
          this.productLoaded = true;
          this.products = [];
          this.viewScroller.setOffset([120, 120]);
          this.viewScroller.scrollToAnchor('products');
        })

      })
    });
  }

  // product Pagination
  setPage(page: number) {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { page: page },
      queryParamsHandling: 'merge', // preserve the existing query params in the route
      skipLocationChange: false  // do trigger navigation
    }).finally(() => {
      this.viewScroller.setOffset([120, 120]);
      this.viewScroller.scrollToAnchor('products'); // Anchore Link
    });
  }

  // Change Grid Layout
  updateGridLayout(value: string) {
    this.grid = value;
  }

  // Change Layout View
  updateLayoutView(value: string) {
    this.layoutView = value;
    if (value == 'list-view')
      this.grid = 'col-lg-12';
    else
      this.grid = 'col-xl-3 col-md-6';
  }

  // Mobile sidebar
  toggleMobileSidebar() {
    this.mobileSidebar = !this.mobileSidebar;
  }

}
