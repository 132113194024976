import { Component, OnInit, Input } from '@angular/core';
import { ProductService } from './../../../shared/services/product.service';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router, Event, NavigationStart, NavigationEnd, NavigationError } from '@angular/router';
@Component({
  selector: 'app-footer-one',
  templateUrl: './footer-one.component.html',
  styleUrls: ['./footer-one.component.scss']
})
export class FooterOneComponent implements OnInit {

  @Input() class: string = 'footer-light' // Default class 
  @Input() themeLogo: string = 'assets/images/blazo-logo.png'; // Default Logo
  @Input() newsletter: boolean = true; // Default True
  public email: any = '';
  public today: number = Date.now();
  newsLetterForm: FormGroup = new FormGroup({});
  enquireForm: FormGroup = new FormGroup({});

  constructor(private productService: ProductService, public router: Router, private toastService: ToastrService, private fb: FormBuilder) {
    this.newsLetterForm = fb.group({
      email: ['', [Validators.required, Validators.email]]
    })
    this.enquireForm = fb.group({
      name: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      phone: [''],
      comment: ['', [Validators.required]],
      address: ['']
    });
  }

  ngOnInit(): void {

    this.router.events.subscribe((event: Event) => {

      if (event instanceof NavigationStart) {
        // Show loading indicator
        console.log("pagename1:" + window.location.pathname);
      }

      if (event instanceof NavigationEnd) {
        // Hide loading indicator
        console.log("pagename2:" + window.location.pathname);
      }

      if (event instanceof NavigationError) {
        // Hide loading indicator
        console.log("pagename3:" + window.location.pathname);
      }

    })

  }



  newsletterSubscription() {
    if (this.newsLetterForm.valid) {
      let obj = {
        "email": this.newsLetterForm.value.email
      }
      this.productService.publishNewsletter(obj).subscribe(res => {
        console.log(res);
        this.toastService.success('Subscribed to email successfully');
        this.newsLetterForm.reset()
      })
    } else {
      if (this.newsLetterForm.value.email == '') {
        this.toastService.error('Please enter email')
      } else {
        this.toastService.error('Please enter valid email')
      }
    }

  }

  submitEnquiry() {
    if (this.enquireForm.valid) {
      let formdata = new FormData();
      formdata.append('name', this.enquireForm.value.name);
      formdata.append('email', this.enquireForm.value.email);
      formdata.append('phone', this.enquireForm.value.phone);
      formdata.append('address', this.enquireForm.value.address);
      formdata.append('comment', this.enquireForm.value.comment);
      this.productService.postEnquiry(formdata).subscribe(res => {
        this.toastService.success('Enquiry Submitted Successfully');
        this.enquireForm.reset()
      })
    } else {
      this.toastService.error("Please fill valid values")
    }
  }



}
