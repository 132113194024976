<!-- <app-breadcrumb [title]="''" [catname]="catname" [id]="id"></app-breadcrumb> -->
<!-- section start -->
<section class="custom-space bg-back ColsSetting min-h">
    <div class="container-fluid">
        <!-- <div class="row justify-content-end mb-2">
            <div class="col-lg-3 col-md-6 col-12" *ngIf="!message">
                <div class="sort-sortBy _custom productSortBy-box">
                    <div class="productSortBy">
                        <p>Sort by : <span>{{selectedSort}}</span></p>
                        <i class="material-icons">arrow_drop_down</i>
                    </div>

                    <ul class="sort-list">
                        <li (click)="updateFilter({'sortBy':'latest'});selectedSort='Whats New'">
                            <label class="sort-label "><input type="radio" value="new">What's New</label>
                        </li>
                        
                        <li (click)="updateFilter({'sortBy':'DESC'});selectedSort='Price: High to Low'"><label
                                class="sort-label "><input type="radio" value="price_desc">Price: High to Low</label>
                        </li>

                        <li (click)="updateFilter({'sortBy':'ASC'});selectedSort='Price: Low to High'"><label
                                class="sort-label "><input type="radio" value="price_asc">Price: Low to High</label>
                        </li>
                    </ul>
                </div>
            </div>
        </div> -->
        <div class="row">
            <div class="Breadcrumbs">
                <app-breadcrumb [title]="''" [catname]="catname" [id]="id"></app-breadcrumb>
            </div>
            <div class="SubCategorys">
                <ul>
                    <li *ngFor="let item of filterCategories">
                        <input type="checkbox" [id]="item.subcat_id" (change)="addCatFilter($event)" [value]="item.slug" id="01" />
                        <label [for]="item.subcat_id">{{item.name}}</label>
                    </li>
                </ul>
            </div>
        </div>
        <div class="row">
            <!-- <div class="col-lg-3 col-md-12 col-12">
                <div class="FilterSidebar">
                    <div class="FilterContainer">
                        <div class="Filter-head">
                            <h4>Filter by</h4>
                            <button type="button">Clear Filter</button>
                        </div>
    
                        <div class="FilterBox">
                            <div class="FilterTitle">
                                <h5>Sub-Category</h5>
                            </div>
    
                            <div class="FilterCategories">
                                <div class="collection-collapse-block" [class.open]="collapse">
                                    <div class="CollapseHead" (click)="collapse = !collapse">
                                        <h3 class="collapse-block-title">{{catname}}</h3>
                                        <i class="material-icons">arrow_drop_down</i>
                                    </div>
    
                                    <div class="collection-collapse-block-content">
                                        <div class="collection-brand-filter">
                                            <div class="CustomChecks">
                                                <div class="Checks-box" *ngFor="let item of filterCategories">
                                                    <input type="checkbox" [id]="item.subcat_id"
                                                        (change)="addCatFilter($event)" [value]="item.slug"
                                                        id="01" />
                                                    <label [for]="item.subcat_id">{{item.name}}</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
    
                        <div class="FilterBox">
                            <div class="FilterTitle">
                                <h5>Price</h5>
                            </div>
                            
                            <div *ngIf="filterData" class="PriceSlider">
                                <ng5-slider [(value)]="minValue" (userChangeEnd)="appliedFilter($event)"
                                    [(highValue)]="maxValue" [options]="options">
                                </ng5-slider>
                            </div>
                        </div>
    
                        <div class="FilterBox">
                            <div class="FilterTitle">
                                <h5><strong>Pack Size</strong></h5>
                            </div>
                            
                            <ul class="PackSizes">
                                <li *ngFor="let item of filterSizes">
                                    <div class="Fill-checkBox">
                                        <input type="checkbox" (change)="addWeightFilter($event)"
                                            [value]="item.weight+''+item.type" [id]="item.weight+''+item.type" />
                                        <label [for]="item.weight+''+item.type">
                                            <span>{{item.weight}} {{item.type}}</span>
                                        </label>
                                    </div>
                                </li>
                            </ul>
                            
                        </div>
                    </div>

                    <div class="bannerGrid-container">

                        <div class="bannerGrid-box bg-secondry">
                            <div class="bannerGrid-details">
                                <img src="assets/images/offer-img2.png">
                                <h3>Offers</h3>
                            </div>
                        </div>

                        <div class="centerBannerGrid-box">
                            <img src="assets/images/bulk-order2.png">
                        </div>

                        <div class="bannerGrid-box bg-secondry">
                            <div class="bannerGrid-details">
                                <img src="assets/images/offer-img.png">
                                <h3>Top Deals</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div> -->
            <div class="col-lg-2 col-6 p-0" *ngFor="let productData of productData">
                <div class="defaultProduct-grid">
                    <div class="productItem-box">
                        <app-product-box-one [productData]="productData" [loader]="true"></app-product-box-one>
                    </div>
                </div>
            </div>

            <div class="col-lg-12" *ngIf="message">
                <div class="noFound text-center" >
                    <img src="assets/images/error.png" class="img-fluid mb-4">
                    <h3>Sorry! Couldn't find the product you were looking For!!! </h3>
                    <p>Please check if you have misspelt something or try searching with
                        other words.</p>
                    <a class="btn ThemeBtn" routerLink="/home">continue shopping</a>
                </div>
            </div>

            <!-- <div class="col-lg-12 col-md-12 col-12">
                <div class="collection-product-wrapper" id="products">
                    <div class="product-wrapper-grid" [ngClass]="layoutView">
                        <div class="row">
                            
                        </div>
                    </div>
                </div>
            </div> -->
        </div>
    </div>
</section>

<!-- <section class="bannerCat">
    <div class="container">
       <div class="row">
          <div class="col-lg-4 col-md-4 col-12">
             <div class="bannerCatBox">
                <img src="assets/images/icon/SanitisedEnvironment.svg">
                <h6>Sanitised Environment</h6>
             </div>
          </div>
 
          <div class="col-lg-4 col-md-4 col-12 centerCol">
             <div class="bannerCatBox">
                <img src="assets/images/icon/HygienicallyProcessed.svg">
                <h6>Hygienically Processed</h6>
             </div>
          </div>
 
          <div class="col-lg-4 col-md-4 col-12 rightCol">
             <div class="bannerCatBox">
                <img src="assets/images/icon/GuaranteedTaste.svg">
                <h6>Guaranteed with Taste</h6>
             </div>
          </div>
       </div>
    </div>
 </section> -->
<!-- section End -->