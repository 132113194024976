<!--footer section -->
<section class="mt-5">
   <div class="container">
      <div class="row">
         <div class="col-lg-12">
            <div class="InnerNewLetter">
               <div class="FormGroup">
                  <label>JOIN OUR NEWSLETTER</label>
                  <input type="text" placeholder="Enter your email address here">
               </div>
            </div>
            <div class="IconList">
               <ul class="FooterSocials">
                  <li>
                     <a href="javascript:void(0)">
                        <img src="assets/images/social-icon/facebook.svg"/>
                     </a> 
                  </li>
                  <li>
                     <a href="javascript:void(0)">
                        <img src="assets/images/social-icon/insta.svg"/> 
                     </a>
                  </li>
                  <li>
                     <a href="javascript:void(0)">
                        <img src="assets/images/social-icon/twiter.svg"/>
                     </a>
                  </li>
                  <li>
                     <a href="javascript:void(0)">
                        <img src="assets/images/social-icon/linkedin.svg"/>
                     </a>
                  </li>

                  <li>
                     <a href="javascript:void(0)">
                        <img src="assets/images/social-icon/youtube.svg"/>
                     </a>
                  </li>
               </ul>
            </div>
         </div>
      </div>
   </div>
</section>
<footer [class]="class" id="footer">
   <div class="container">
      <div class="row">
         <div class="col-lg-3 col-md-4 col-12">
            <div class="FooterCol">
               <h5 class="FooterCol-Title">Help</h5>
               <ul class="FooterLinks">
                  <li><a href="javascript:void(0)" routerLink="/pages/aboutus">About us</a></li>
                  <li><a href="javascript:void(0)" routerLink="/pages/contact">Contact us</a></li>
                  <li><a href="javascript:void(0)">Track your order</a></li>
                  <li><a href="javascript:void(0)">Pincode list</a></li>
               </ul>
            </div>
         </div>

         <div class="col-lg-3 col-md-4 col-12">
            <div class="FooterCol">
               <h5 class="FooterCol-Title">Quick Links</h5>
               <ul class="FooterLinks">
                  <li><a href="javascript:void(0)" routerLink="/pages/aboutus">About us</a></li>
                  <li><a href="javascript:void(0)" routerLink="/pages/contact">Contact us</a></li>
                  <li><a href="javascript:void(0)">Track your order</a></li>
                  <li><a href="javascript:void(0)">Pincode list</a></li>
               </ul>
            </div>
         </div>

         <div class="col-lg-3 col-md-4 col-12">
            <div class="FooterCol">
               <h5 class="FooterCol-Title">Information</h5>
               <ul class="FooterLinks">
                  <li><a href="javascript:void(0)">Terms</a></li>
                  <li><a href="javascript:void(0)">Privacy</a></li>
                  <li><a href="javascript:void(0)">Returns & Refunds</a></li>
                  <li><a href="javascript:void(0)">Brand history</a></li>
               </ul>
            </div>
         </div>

         <div class="col-lg-3 col-md-4 col-12">
            <div class="FooterCol">
               <h5 class="FooterCol-Title">Contact Us</h5>
               <h6>A-220, 2nd floor, Sigma estate 1, Plot no. 1025, Sayani road , Near Ashish Ind Estate , Dadar West , Prabhadevi, Mumbai 400025, India</h6>

               <h5 class="FooterCol-Title">Payment Method</h5>

               <div class="paymentMethod">
                  <img src="assets/images/icon/master-card.svg"/>
                  <img src="assets/images/icon/visa-card.svg"/>
               </div>
            </div>
         </div>
      </div>
   </div>
</footer>
<!--footer section end -->
