<div class="icon-nav">
   <ul class="main_ul">
      <li class="mobile-search search-widgets" id="search-widgets">
         <a href="javascript:void(0);" class="searchIcon" (click)="toggleShow()">
            <span class="material-icons">
               search
            </span>
         </a>

         <div class="SearchBar" *ngIf="isShown" id="divshow">
            <input placeholder="Search Here...." type="search" [(ngModel)]="placeId"
               (keyup.enter)="Searchbutton(placeId)" class="customsaech" />
            <button class="CustomSaechBTn" (click)="Searchbutton(placeId)">
               <span class="material-icons">
                  search
               </span>
            </button>
         </div>
      </li>

      <li *ngIf="!isLoggedIn" class="onhover-div mobile-users">
         <a routerLink="pages/login">
            <span class="material-icons">
               person
            </span>
         </a>
      </li>

      <li *ngIf="isLoggedIn" class="onhover-div mobile-users">
         <a href="javascript:void(0);" class="User-Profile">
            <span class="material-icons">
               person
            </span>
            <p class="UserName">{{userName}}</p>
         </a>

         <ul *ngIf="isLoggedIn" class="show-div UserProfile-menus">
            <li>
               <a routerLink='/pages/orders'>
                  <i class="fa fa-cart-arrow-down" aria-hidden="true"></i>
                  <span>Your Orders</span>
               </a>
            </li>
            <li>
               <a routerLink="/pages/profile">
                  <i class="fa fa-user-circle-o" aria-hidden="true"></i>
                  <span> Your Account</span>
               </a>
            </li>
            <li>
               <a routerLink="/pages/contact">
                  <i class="fa fa-phone-square" aria-hidden="true"></i>
                  <span>Contact us</span>
               </a>
            </li>
            <li>
               <a href="javascript:void(0);" (click)="logout()">
                  <i class="fa fa-sign-out" aria-hidden="true"></i>
                  <span>Logout</span>
               </a>
            </li>
         </ul>
      </li>

      <li class="onhover-div mobile-cart">
         <div class="cartBox">
            <a href="javascript:void(0);" (click)="CartsidebarToggle()">
               <span class="material-icons">
                  shopping_cart
               </span>
            </a>
            <span class="cart_qty_cls" *ngIf="products">{{ products?.length }}</span>

            <span class="cart_qty_cls" *ngIf="!products">0</span>
         </div>

         <div class="cart-box" [class.opensetting]="Cartsidebar">
            <a href="javascript:void(0)" class="sidebar-overlay"></a>
            <div class="CartItems">
               <ul class="shopping-cart" *ngIf='!products?.length'>
                  <div class="Close_Btn">
                     <a href="javascript:void(0)" (click)="CartsidebarToggle()">
                        <span class="material-icons"> close </span>
                     </a>
                  </div>
                  <img src="assets/images/error.png" class="img-fluid mb-4">
                  <h5 class="empty-alert">Your cart is currently empty.</h5>
               </ul>

               <div class="shopping-cart" *ngIf='products?.length > 0'>
                  <div class="CartTOPBar">
                     <h4>Items Added To Your Shopping Bag</h4>
                     <div class="Close_Btn">
                        <a href="javascript:void(0)" (click)="CartsidebarToggle()">
                           <span class="material-icons"> close </span>
                        </a>
                     </div>
                  </div>
                  <ul class="">
                     <li *ngFor="let product of products;let i=index">
                        <div class="CartItemsBag">
                           <div class="CartItemImg">
                              <img [src]="product.master_image">
                           </div>
                           <div class="CartItems-Body custom_cats">
                              <a *ngIf="isLoggedIn">
                                 <h5>{{ product.title }}</h5>
                              </a>
                              <a *ngIf="!isLoggedIn">
                                 <h5>{{ product.title }}</h5>
                              </a>
                              <h4 *ngIf="isLoggedIn" class="currency_tab">
                                 <span> {{ product.product_qty }} </span> <span class="material-icons f-16"> close </span>
                                     <span class="Rupay"><span class="material-icons f-16"> currency_rupee </span> {{ product.product_price}}</span> 
                              </h4>
                              <h4 *ngIf="!isLoggedIn">
                                 <p class="m-0">
                                    {{ product.qty }} x
                                    <span class="material-icons"> currency_rupee </span> {{ product.price}}
                                 </p>
                              </h4>
                           </div>
                        </div>
                        <div class="close-btn" (click)="removeItem(product,i)">
                           <a href="javascript:void(0)"><span class="material-icons"> close </span></a>
                        </div>
                     </li>
                  </ul>
      
                  <div class="bottomBars">
                     <div class="total">
                        <h5>Subtotal : <span class="FlexSub"><span class="material-icons f-16"> currency_rupee </span> {{sum.toFixed(2)}}</span></h5>
                     </div>
                     <div class="checkoutBtn">
                        <a [routerLink]="['/shop/cart']" (click)="CartsidebarToggle(); redirect()" class="view-cart">view cart and checkout</a>
                     </div>
                  </div>
                 
               </div>
            </div>
            
         </div>
      </li>
   </ul>
</div>