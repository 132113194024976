<header class="sticky" [class.fixed]="stick && sticky">
   <div class="container-fluid">
      <div class="row">
         <div class="col-lg-12">
            <div class="main-menu">
               <!-- Mobile Nav -->
               <div class="menu-left">
                  <div class="mobile_opens"> 
                     <app-left-menu></app-left-menu>
                     <div class="MObileSetting">
                        <app-settings></app-settings>
                     </div>
                  </div>
                  <a routerLink="/home" class="BrandLogos">
                     <img [src]="themeLogo" alt="logo">
                  </a>
               </div>
               <div class="rightItem">
                  <div class="menu-right left-menu">
                     <app-menu></app-menu>
                     <app-settings></app-settings>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</header>