<!-- <app-breadcrumb [title]="product?.title" class="Product-Details-Breadcrumb" [catname]="catname"
   [breadcrumb]="'Product'"></app-breadcrumb> -->

<section class="ProductDetails-Container custom-space">
   <div class="container-fluid">
      <div class="row">
         <div class="col-lg-4 d-none d-lg-block">
            <div class="Composition_box">
               <div class="InnerBox">
                  <h4>COMPOSITION, CARE & ORIGIN</h4>
                  <h4>COMPOSITION</h4>
                  <p>We work with monitoring programmes to ensure compliance with our social, environmental and health
                     and safety standards for our products. To assess compliance, we have developed a programme of
                     audits and continuous improvement plans.</p>
                  <p>OUTER SHELL 100% cotton</p>
               </div>
            </div>
         </div>
         <div class="col-lg-4 p-0 pl-lg-3 pr-lg-3">
            <div class="mainProduct-ThumbnailBox">
               <div class="activeProduct">

                  <!-- <img id="gfg-img" [src]="productdetails.master_image" class="img-fluid"> -->
                  
                  <!-- <owl-carousel-o [options]="ProductDetailsMainSliderConfig" #owlCar class="ProductCarousel">
                     <ng-container *ngFor="let Master_image of productdetails.master_image; index as i">
                        <ng-template carouselSlide [id]="i">
                           <div class="ThumbnailImageBox">
                              <img id="gfg-img" [src]="Master_image" onError="this.src='https://via.placeholder.com/150'"  class="img-fluid">
                           </div>
                        </ng-template>
                     </ng-container>
                  </owl-carousel-o> -->
                  <div class="keen-slider" #sliderRef >
                     <div class="keen-slider__slide">
                        <img [src]="productdetails.master_image"  class="img-fluid">
                     </div>
                   </div>
                   <!-- <div class="keen-slider thumbnail" #thumbnailRef>
                     <div class="keen-slider__slide number-slide1">
                        <img [src]="productdetails.master_image"  class="img-fluid">
                     </div>
                   </div> -->
               </div>
               <!-- <div class="sliderNav">
                  <owl-carousel-o [options]="ProductDetailsThumbConfig">
                     <ng-container *ngFor="let image of productdetails.master_image; index as i">
                        <ng-template carouselSlide [id]="i">
                           <div class="owl-thumb" [class.active]="i == activeSlide">
                              <div class="ThumbnailImageBox">
                                 <img [src]="image" onError="this.src='https://via.placeholder.com/150'" alt="prabhujipurefood" class="img-fluid" (click)="owlCar.to(activeSlide = i.toString())">
                              </div>
                           </div>
                        </ng-template>
                     </ng-container>
                  </owl-carousel-o>
               </div> -->
            </div>
         </div>

         <div class="col-lg-4 pt-4 pt-lg-0">
            <div class="product-detailsOverview">
               <div class="TopPart">
                  <h2 class="productTitle">{{productdetails.title}}</h2>
                  <div class="productPrice">
                     <h3 class="saleprice">Rs. {{offer_price}}/-</h3>

                     <h3 *ngIf="offer_price!=main_price" class="mainprice">
                        <span></span>
                     </h3>
                  </div>
                  <div class="disclaimer">
                     <p [innerHTML]="productdetails.disclaimer"></p>
                  </div>

                  <div class="priceSku">

                     <div class="skuTags">
                        <p class="skus">Products Sku - {{productdetails.sku}}</p>
                        <p *ngIf="product_stock > 0" class="stock">
                           <span class="stock_dots"></span>IN STOCK
                        </p>

                        <p *ngIf="product_stock === 0" class="stock outofStock">
                           <span class="stock_dots"></span>OUT OF STOCK
                        </p>
                     </div>
                     <div class="Chekin">
                        <p>CHECK IN-STORE AVAILABILITY</p>
                     </div>
                     <div class="Shipping">
                        <p>SHIPPING, EXCHANGES AND RETURNS</p>
                     </div>
                  </div>
               </div>
               <div class="MeddlePart">
                  <div class="weightDetails">
                     <div class="ColorPart">
                        <p>Colour : | TEst /4653</p>

                        <div class="SubCategorys">
                           <ul>
                              <li>
                                 <input type="radio" id="01" name="colour" value="red" />
                                 <label for="01"></label>
                              </li>
                              <li>
                                 <input type="radio" id="02" name="colour" value="blue" />
                                 <label for="02"></label>
                              </li>
                              <li>
                                 <input type="radio" id="03" name="colour" value="yellow" />
                                 <label for="03"></label>
                              </li>
                              <li>
                                 <input type="radio" id="04" name="colour" value="gray" />
                                 <label for="04"></label>
                              </li>
                           </ul>
                        </div>
                     </div>

                     <div class="SizePart">
                        <div class="SizeVari">
                           <ul>
                              <li>
                                 <input type="radio" id="s" name="size" value="s" />
                                 <label for="s">S (UK S)</label>
                              </li>
                              <li>
                                 <input type="radio" id="m" name="size" value="m" />
                                 <label for="m">M (UK M)</label>
                              </li>
                              <li>
                                 <input type="radio" id="l" name="size" value="l" />
                                 <label for="l">L (UK L)</label>
                              </li>
                              <li>
                                 <input type="radio" id="xl" name="size" value="xl" />
                                 <label for="xl">XL (UK XL)</label>
                              </li>
                              <li>
                                 <input type="radio" id="xxl" name="size" value="xxl" />
                                 <label for="xxl">XXL (UK XXL)</label>
                              </li>
                           </ul>
                        </div>
                        <div class="pt-4">
                           <p class="m-0">FIND YOUR SIZE | MEASUREMENT GUIDE</p>
                        </div>
                       
                     </div>

                     <!-- <div class="selectBox">
                        <select *ngIf="productdetails.product_variant" (change)="weightChange($event)">
                           <option *ngFor="let product of productdetails.product_variant.weight;let i=index;"
                              value="{{i}}">
                              {{product}}{{productdetails.product_variant.weight_type[i]}}
                           </option>
                        </select>
                     </div> -->
                  </div>
               </div>
               <div class="BottomPart">
                  <button type="submit" [disabled]="product_stock === 0" class="btn ThemeBtn addCart-btn"
                     (click)="addtocart(productdetails)">Add to cart</button>
               </div>


               <!-- <div class="productBtns_groups">
                  <div class="qty-box bg-secondry">
                     <h5>Qty.</h5>
                     <div class="qtyInput-group">
                        <button type="button" class="btn quantity-left-minus" data-type="minus" (click)="decrement()">
                           <i class="material-icons">remove</i>
                        </button>
                        
                        <input type="text" name="quantity" class="form-control input-number" [value]="counter" disabled />
                        
                        <button type="button" class="btn quantity-right-plus" data-type="plus" (click)="increment()">
                           <i class="material-icons">add</i>
                        </button>
                     </div>
                  </div>
      
                  <button type="submit" [disabled]="product_stock === 0" class="btn ThemeBtn addCart-btn" (click)="addtocart(productdetails)">Add to cart</button>
                  
                  <button type="submit" class="btn ThemeBtn wishList-btn bg-secondry" (click)="addtowish(productdetails)">Wishlist</button>
               </div> -->

               <!-- <div class="deliveryOption">
                  <label for="">Delivery Options</label>
                  <div class="pinCode-input">
                     <input type="text" name="pincode" placeholder="Enter pincode" class="form-control"/>
                     <button>Check</button>
                  </div>
               </div> -->

               <!-- <div class="productSocial">
                  <share-buttons [theme]="'material-dark'"
                     [include]="['facebook','pinterest','twitter','whatsapp']" [show]="4" [size]="-4"
                     [url]="url" [autoSetMeta]="false">
                  </share-buttons>
               </div> -->
            </div>
         </div>
      </div>
   </div>
   <app-related-product></app-related-product>
</section>