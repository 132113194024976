<!-- product section start -->
<section class="custom-space relateds pb-0">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12 product-related text-left">
                <h2>You may also like</h2>
            </div>
        </div>
        <div class="row search-product">
            <div class="col-lg-2 col-6 p-0" *ngFor="let productData of productData ">
                <div class="defaultProduct-grid">
                    <div class="productItem-box">
                        <app-product-box-one 
                        [productData]="productData" 
                        [currency]="productService?.Currency"
                        [cartModal]="true">
                      </app-product-box-one>
                    </div>
                   
                </div>
            </div>
        </div>
    </div>
</section>
<!-- product section end -->