import { Component, OnInit, PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { ProductService } from "../../services/product.service";
import { Product } from "../../classes/product";
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {
  public Cartsidebar: boolean = false;
  public products: any = [];
  public wishListItems: Product[] = [];
  public isLoggedIn: boolean = false;
  public loggedIn: any;
  public userName: any = ''
  public languages = [{
    name: 'English',
    code: 'en'
  }, {
    name: 'French',
    code: 'fr'
  }];

  public currencies = [{
    name: 'Euro',
    currency: 'EUR',
    price: 0.90 // price of euro
  }, {
    name: 'Rupees',
    currency: 'INR',
    price: 70.93 // price of inr
  }, {
    name: 'Pound',
    currency: 'GBP',
    price: 0.78 // price of euro
  }, {
    name: 'Dollar',
    currency: 'USD',
    price: 1 // price of usd
  }];
  sum: any = 0
  length: any;
  productid: any;
  empty: boolean = false;
  placeId: any;
  constructor(@Inject(PLATFORM_ID) private platformId: Object,
    private router: Router,
    private translate: TranslateService,
    public productService: ProductService, private toastService: ToastrService) {
    this.productService.cartItems.subscribe(response => {
      this.products = response['data'];
      //console.log(this.products)
      let self = this;
      self.sum = 0;
      this.products.forEach(element => {
        // console.log(element)
        self.sum = self.sum + element['total_amt']
      });
    });
    this.productService.productAddUpdate$.subscribe((profileImage) => {
      if(isPlatformBrowser(this.platformId)){
        if (localStorage.isLoggedIn) {
          this.productService.cartItems.subscribe(response => {
            this.products = response['data'];
            console.log(this.products)
            let self = this;
            self.sum = 0;
            if (this.products?.length) {
              this.products.forEach(element => {
                // console.log(element)
                self.sum = self.sum + element['total_amt']
              });
            }
            this.length = this.products.length
          });
        }
        // console.log("length", this.length)
        localStorage.setItem("length", this.length)
        if (!localStorage.isLoggedIn) {
          // this.products = JSON.parse(localStorage.getItem("cartdata"));
          this.products = JSON.parse(localStorage.getItem("cartdata"));
          this.length = this.products.length
          // console.log("length", this.length, this.products.length)
          let self = this;
          self.sum = 0;
          if (this.products.length) {
            this.products.forEach(element => {
              // console.log(element)
              self.sum = self.sum + element['price'] * element['qty']
            });
            // this.length = this.products.length + 1
            // console.log("length",this.length+1, this.products.length)
          }
          else {
            this.length = this.products.length
          }
          this.productService.refreshData.subscribe(res => {
            this.products = JSON.parse(localStorage.getItem("cartdata"));
            this.length = this.products.length
            // console.log("length", this.length, this.products.length)
            let self = this;
            self.sum = 0;
            if (this.products.length) {
              this.products.forEach(element => {
                // console.log(element)
                self.sum = self.sum + element['price'] * element['qty']
              });
              // this.length = this.products.length + 1
              // console.log("length",this.length+1, this.products.length)
            }
            else {
              this.length = this.products.length
            }
          })
  
        }
      }
      
    });

  }

  isShown: boolean = false; // hidden by default


  toggleShow() {

    this.isShown = !this.isShown;

  }

  ngOnInit(): void {
    
    if(isPlatformBrowser(this.platformId)){
      this.loggedIn = localStorage.isLoggedIn
      this.productService.userLoggedOutorIn$.subscribe((res) => {
        // console.log(res);
        if (res == 0) {
          this.isLoggedIn = false;
          // this.products=[];
          this.products = JSON.parse(localStorage.getItem("cartdata"))
          this.wishListItems = [];
        } else {
          this.isLoggedIn = true;
          var userInfo = localStorage.getItem("userinfo");
  
          this.productService.productAddUpdate$.next();
          this.productService.wishLishtUpdated$.next()
          this.userName = (JSON.parse(userInfo).name != '') ? JSON.parse(userInfo).name : 'Guest'
        }
      })
  
      if (!localStorage.isLoggedIn) {
        this.products = JSON.parse(localStorage.getItem("cartdata"));
        let self = this;
        self.sum = 0;
        if (this.products.length) {
          this.products.forEach(element => {
            // console.log(element)
            self.sum = self.sum + element['total_amt']
          });
        }
        else {
          this.length = this.products.length
        }
      }
    }
    

    // this.productService.productAddUpdate$.subscribe((profileImage) => {
    //   alert('subc1');
    //   if (localStorage.isLoggedIn) {
    //     this.productService.cartItems.subscribe(response => {
    //       this.products = response['data'];
    //       let self = this;
    //       self.sum = 0;
    //       if (this.products?.length) {
    //         this.products.forEach(element => {
    //           console.log(element)
    //           self.sum = self.sum + element['total_amt']
    //         });
    //       }
    //       this.length = this.products.length
    //     });
    //   }
    //   console.log("length", this.length)
    //   localStorage.setItem("length", this.length)
    //   if (!localStorage.isLoggedIn) {
    //     alert('subc');
    //     // this.products = JSON.parse(localStorage.getItem("cartdata"));
    //     this.products = JSON.parse(localStorage.getItem("cartdata"));
    //     this.length = this.products.length
    //     console.log("length", this.length, this.products.length)
    //     let self = this;
    //     self.sum = 0;
    //     if (this.products.length) {
    //       this.products.forEach(element => {
    //         console.log(element)
    //         self.sum = self.sum + element['price'] * element['qty']
    //       });
    //       // this.length = this.products.length + 1
    //       // console.log("length",this.length+1, this.products.length)
    //     }
    //     else {
    //       this.length = this.products.length
    //     }
    //     this.productService.refreshData.subscribe(res => {
    //       this.products = JSON.parse(localStorage.getItem("cartdata"));
    //       this.length = this.products.length
    //       console.log("length", this.length, this.products.length)
    //       let self = this;
    //       self.sum = 0;
    //       if (this.products.length) {
    //         this.products.forEach(element => {
    //           console.log(element)
    //           self.sum = self.sum + element['price'] * element['qty']
    //         });
    //         // this.length = this.products.length + 1
    //         // console.log("length",this.length+1, this.products.length)
    //       }
    //       else {
    //         this.length = this.products.length
    //       }
    //     })

    //   }
    // });


    if (isPlatformBrowser(this.platformId)) {
      if (localStorage.getItem("isLoggedIn")) {
        this.isLoggedIn = true;
        var userInfo = localStorage.getItem('userinfo');
        // this.productService.getWishListedProducts({"user_id":localStorage.customerId}).subscribe(
        //   res=>{
        //     console.log(res['data']);
        //     this.wishListItems = res['data'];
        //     console.log(this.wishListItems.length)
        //   }
        // )
        //console.log(JSON.parse(userInfo).name);
        this.userName = (JSON.parse(userInfo).name != '') ? JSON.parse(userInfo).name : 'Guest'
      }
    }
  }

  changeLanguage(code) {
    if (isPlatformBrowser(this.platformId)) {
      this.translate.use(code)
    }
  }
  redirect() {
    this.productService.productAddUpdate$.next('added')
  }
  // get getTotal(): Observable<number> {
  //   return this.productService.cartTotalAmount();
  // }

  public removeItem(product: any, i: any) {
    // console.log("stting ", product);
    if (localStorage.isLoggedIn) {
      let obj =
      {
        "user_id": localStorage.customerId,
        "cart_id": product.id
      }

      this.productService.removeItems(obj).subscribe(res => {
        // console.log(res)
        this.productService.productAddUpdate$.next('added')
        this.toastService.success("Product removed successfully." )

      })
    }
    this.productService.productAddUpdate$.next('added')
    //this.productService.removeCartItem(product);
    if (!localStorage.isLoggedIn) {
      this.products.splice(i, 1)
      // console.log(i, "...index")

      this.products = JSON.parse(localStorage.getItem("cartdata"));
      if (this.products?.length) {
        this.products.splice(i, 1)

        localStorage.setItem("cartdata", JSON.stringify(this.products))
        // this.products = JSON.parse(localStorage.getItem("cartdata"))
        let self = this;
        self.sum = 0;
        this.products.forEach(element => {
          // console.log(element)
          this.sum = parseFloat(element.total_amt) + self.sum
          // console.log(self.sum)
          localStorage.setItem("sum", JSON.stringify(this.sum))
          this.sum = JSON.parse(localStorage.getItem("sum"))
        });
        this.productService.refreshData.next()
      }
    }
  }

  changeCurrency(currency: any) {
    this.productService.Currency = currency
  }

  logout() {
    localStorage.clear();
    this.productService.userLoggedOutorIn$.next(0)
    this.router.navigateByUrl('/');
  }

  Searchbutton(placeId) {

    this.router.navigateByUrl('/shop/search/'+placeId);

  }

    // Sidebar Toggle
    CartsidebarToggle() {
      this.Cartsidebar = !this.Cartsidebar
    }

}


